import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import { Link } from "~components"
import Details from "./style"
import { GatsbyImage } from "gatsby-plugin-image"
import dayjs from "dayjs"
import Sidebar from "./Component/Sidebar"
import makeUrlSafe from "../../libs/safeUrl"

export default function BlogDetails({ content, articlesList }) {
  const nextArticle = () => {
    const index = articlesList.findIndex(article => article.id === content.id)
    return makeUrlSafe(articlesList[index + 1]?.title || "")
  }

  const prevArticle = () => {
    const index = articlesList.findIndex(article => article.id === content.id)
    return makeUrlSafe(articlesList[index - 1]?.title || "")
  }

  return (
    <Details backgroundColor="#f9fafc">
      <Details.Box pb="60px" pbMD="80px" pbLG="130px">
        <Container>
          <Details.Pagination mt="30px" mtLG="75px" mb="50px">
            <Details.PrevButton>
              <Link className="btn-link" to="/articles">
                <i className="fa fa-arrow-left" />
                Retour aux articles
              </Link>
            </Details.PrevButton>
          </Details.Pagination>
          <Row className="justify-content-center">
            <Col className="col-lg-7">
              <Details.Box mb="30px" mbLG="55px">
                <Details.Image mb="35px" mbLG="55px">
                  {content?.localImage?.childImageSharp?.gatsbyImageData ? (
                    <GatsbyImage
                      image={content.localImage.childImageSharp.gatsbyImageData}
                      alt={content?.title || "Image de l'article"}
                    />
                  ) : (
                    <p>🖼️ Image non disponible</p>
                  )}
                </Details.Image>

                <Details.MetaInfo>
                  <Details.Date>
                    {content?.isoDate
                      ? dayjs(content.isoDate).format("DD-MM-YYYY")
                      : "Date inconnue"}
                  </Details.Date>
                </Details.MetaInfo>
              </Details.Box>

              <Details.Box>
                <Details.Text>
                  {typeof content?.content === "string" ? (
                    <div
                      className="blog-article-text"
                      dangerouslySetInnerHTML={{ __html: content.content }}
                    />
                  ) : (
                    <p>⚠️ Contenu non disponible</p>
                  )}
                </Details.Text>

                {Array.isArray(content?.categories) &&
                  content.categories.length > 0 && (
                    <Details.Tag mt="50px">
                      <Details.SubTitle mr="25px" mt="10px" as="h4">
                        Catégories:
                      </Details.SubTitle>
                      <Details.TagList>
                        {content.categories.map((category, i) => (
                          <li key={i}>
                            <span>{category}</span>
                          </li>
                        ))}
                      </Details.TagList>
                    </Details.Tag>
                  )}

                <Details.Pagination mt="30px" mtLG="75px" mb="50px">
                  {prevArticle() && (
                    <Details.PrevButton>
                      <Link
                        className="btn-link"
                        to={`/articles/${prevArticle()}`}
                      >
                        <i className="fa fa-arrow-left" />
                        Précédent
                      </Link>
                    </Details.PrevButton>
                  )}
                  {nextArticle() && (
                    <Details.NextButton>
                      <Link
                        className="btn-link"
                        to={`/articles/${nextArticle()}`}
                      >
                        Suivant
                        <i className="fa fa-arrow-right" />
                      </Link>
                    </Details.NextButton>
                  )}
                </Details.Pagination>
              </Details.Box>
            </Col>

            <Col xs="12" className="col-xl-4 offset-xl-1 col-lg-5">
              <Sidebar articles={articlesList} />
            </Col>
          </Row>
        </Container>
      </Details.Box>
    </Details>
  )
}
